import cx from 'classnames';
import React, { useContext } from 'react';
import { useTranslation } from "react-i18next"

import { Button } from '../components/button';
import { Container } from '../components/container';
import { FoodCarouselSection2 } from '../components/food2';
import { Order2 } from '../components/order-form2';
import { AffiliateContext } from '../contexts/AffiliateContext';
import { useSmoothScroll } from '../hooks/useSmoothScroll';
import productImg1 from '../images/product-1-bg.jpg';
import productImg2 from '../images/product-2-bg.jpg';
import productImg3 from '../images/product-3-bg.jpg';
import productImg4 from '../images/product-4-bg.jpg';
import commonStyles from './common.module.css';
import styles from './hero.module.css';
import s1icon1 from './icons/s1-icon1.svg';
import s1icon2 from './icons/s1-icon2.svg';
// import s1icon3 from './icons/s1-icon3.svg';
import s3icon1 from './icons/s3-icon1.svg';
import s3icon2 from './icons/s3-icon2.svg';
import s3icon3 from './icons/s3-icon3.svg';
import s3icon4 from './icons/s3-icon4.svg';
import section1 from './section1.module.css';
// import section2 from './section2.module.css';
// import section3 from './section3.module.css';
import section4 from './section4.module.css';

// import { Calculator2 } from '../components/calculator2';
// import angleDownIcon from './icons/angle-down-icon.svg';
const ProductTemplate = ({ productId, title, productList }) => {
  const scroll = useSmoothScroll()

  const scrollTo = selector => () => {
    selector && scroll.animateScroll(document.getElementById(selector))
  }

  const img = productId => {
    let img = null
    switch (true) {
      case productId === "1":
        img = productImg1
        break
      case productId === "2":
        img = productImg2
        break
      case productId === "3":
        img = productImg3
        break
      case productId === "4":
        img = productImg4
        break
      default:
        img = productImg1
        break
    }
    return img
  }

  const affiliateContext = useContext(AffiliateContext);
  const { isAffiliate } = affiliateContext.affiliate;
  const { t } = useTranslation();

  return (
    <>
      <section className={styles.productHero}>
        <img src={img(productId)} className={styles.productHeroImg} alt="bg" />
        <div className={styles.productHeroCaption}>
          <Container className={styles.heroContainer}>
            <h1 className={styles.productHeroTitle}>{title}</h1>
            <ul className={styles.productHeroList}>{productList()}</ul>
            <Button
              type="primary"
              className={styles.productHeroBtn}
              handleClick={scrollTo("calculator")}
            >
              {t("home.program2.productHeroBtn")}
            </Button>
          </Container>
        </div>
      </section>
      <section className={cx("section", section1.section)}>
        <Container>
          <h3 className={cx("sectionTitle", section1.title)}>
          {t("home.program2.program1Title2")}
          </h3>
          <p className={section1.text}>
          {t("home.program2.program1Text1")}
          </p>
        </Container>
        <Container>
          <div className={section1.cardGrid}>
            <div className={section1.card}>
              <div className={section1.cardIcon}>
                <img src={s1icon1} alt="icon" />
              </div>
              <div className={section1.cardBody}>
                <h5 className={section1.cardTitle}>{t("home.program2.program1Title3")}</h5>
                <p className={section1.cardText}>
                  {t("home.program2.program1Text2")}
                </p>
              </div>
            </div>
            <div className={section1.card}>
              <div className={section1.cardIcon}>
                <img src={s1icon2} alt="icon" />
              </div>
              <div className={section1.cardBody}>
                <h5 className={section1.cardTitle}>{t("home.program2.program1Title4")}</h5>
                <p className={section1.cardText}>
                {t("home.program2.program1Text2")}
                </p>
              </div>
            </div>
            {/* <div className={section1.card}>
              <div className={section1.cardIcon}>
                <img src={s1icon3} alt="icon" />
              </div>
              <div className={section1.cardBody}>
                <h5 className={section1.cardTitle}>
                  Možnost vyloučit až 3 potraviny
                </h5>
                <p className={section1.cardText}>
                  Je něco, co nejíš nebo ti nechutná? Nevadí. U nás můžeš z
                  jídelníčku vyloučit až 3 druhy potravin.
                </p>
              </div>
            </div> */}
          </div>
          {/* <div className="text-center">
            <button
              type="button"
              className={section1.link}
              onClick={scrollTo("section-2")}
            >
              Vzorový jídelníček
              <img src={angleDownIcon} alt="icon" />
            </button>
          </div> */}
        </Container>
      </section>
      {/* <section className={cx("section", section2.section)} id="section-2">
        <Container className={section2.container}>
          <h3 className={cx("sectionTitle -light", section2.title)}>
            Diagnostika těla
          </h3>
          <p className={section2.text}>
            Změříme tě na přístroji Tanita, který nám ukáže, jaký máš poměr mezi
            svalovou a tukovou hmotou, a ukáže množství útrobního tuku a vody v
            těle.
          </p>
          <p className={section2.text}>
            Tyto informace jsou nezbytné nejen pro nastavení individuálního
            jídelníčku, ale také pro kontrolu tvých výsledků.
          </p>
          <p className={section2.text}>
            Jednou za 14 dní tě přeměří náš zaškolený pracovník, a to na místě,
            které si sám určíš.
          </p>
        </Container>
      </section> */}
      {/* <section className={cx("section", section3.section)}>
        <Container className={section3.container}>
          <h3 className={cx("sectionTitle", section3.title)}>
            Dohled výživového poradce
          </h3>
          <p className={section3.text}>
            Dietolog se stará o to, aby cesta za tvým cílem byla co možná
            nejsnazší. Dohlíží na plnění cílů a podle výsledků měření a tvé
            zpětné vazby, upravuje jídelníček. Každý měsíc ti od něj přijde
            vyhodnocení, kde se přesně dozvíš, jak si vedeš.
          </p>
          {/* <div className="text-center">
            <button
              type="button"
              className={section3.link}
              onClick={scrollTo("section-4")}
            >
              Více info o našem výživovém poradci
              <img src={angleDownIcon} alt="icon" />
            </button>
          </div> */}
      {/* </Container>
      </section> */}
      <section className={cx("section", section4.section)} id="section-4">
        <Container>
          <h3 className={cx("sectionTitle", section4.title)}>
          {t("home.program2.program1Title5")}
          </h3>
          <p className={section4.text}>
          {t("home.program2.program1Text4")}
          </p>
        </Container>
        <Container>
          <div className={section4.cardGrid}>
            <div className={section4.card}>
              <div className={section4.cardIcon}>
                <img src={s3icon1} alt="icon" />
              </div>
              <div className={section4.cardBody}>
                <h5 className={section4.cardTitle}>
                  {t("home.program2.program1Card1.title")}
                  </h5>
                <p className={section4.cardText}>
                  {t("home.program2.program1Card1.text")}
                </p>
              </div>
            </div>
            <div className={section4.card}>
              <div className={section4.cardIcon}>
                <img src={s3icon2} alt="icon" />
              </div>
              <div className={section4.cardBody}>
                <h5 className={section4.cardTitle}>
                  {t("home.program2.program1Card2.title")}
                </h5>
                <p className={section4.cardText}>
                  {t("home.program2.program1Card2.text")}
                </p>
              </div>
            </div>
            <div className={section4.card}>
              <div className={section4.cardIcon}>
                <img src={s3icon3} alt="icon" />
              </div>
              <div className={section4.cardBody}>
                <h5 className={section4.cardTitle}>
                  {t("home.program2.program1Card3.title")}
                </h5>
                <p className={section4.cardText}>
                  {t("home.program2.program1Card3.text")}
                </p>
              </div>
            </div>
            <div className={section4.card}>
              <div className={section4.cardIcon}>
                <img src={s3icon4} alt="icon" />
              </div>
              <div className={section4.cardBody}>
                <h5 className={section4.cardTitle}>
                  {t("home.program2.program1Card4.title")}
                </h5>
                <p className={section4.cardText}>
                  {t("home.program2.program1Card4.text")}
                </p>
              </div>
            </div>
          </div>
          {/* <div className={cx("text-center", section4.buttons)}>
            <div className={section4.buttonsTitle}>Vozíme i k Vám?</div>
            <Button type="outline" className={section4.button}>
              Zkontrolovat adresu
            </Button>
          </div> */}
        </Container>
      </section>
      <Order2 className={commonStyles.calculator} isAffiliate={isAffiliate} id="calculator" />
      <FoodCarouselSection2 />
    </>
  )
}

export default ProductTemplate
